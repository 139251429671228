.button {
  position: relative;

  &.variant-secondary {
    background: rgb(26 58 50 / 0.06);
    border-color: rgb(26 58 50 / 0.06);
    color: rgb(26 58 50 / 1);

    &:hover {
      background: rgb(26 58 50 / 0.15) !important;
      border-color: rgb(26 58 50 / 0.15) !important;
    }
  }

  &.variant-custom-primary-outline {
    border: 1px solid rgb(26 58 50 / 0.70);

    &:hover {
      background: rgb(7 116 91 / 0.05) !important;
      border-color: rgb(26 58 50) !important;
    }
  }

  &.variant-dark {
    background: #141414;
    border: 1px solid rgb(255 252 249 / 0.12);
    color: white;

    &:hover {
      background: rgb(255 252 249 / 0.06);
    }
  }

  &.variant-turquoise {
    background: rgb(222 255 248 / 0.9);
    color: #1a2a32;

    &:hover {
      background: rgb(222 255 248);
    }
  }

  &.variant-pink {
    background: var(--color-pink-90);
    color: var(--color-marroon);

    &:hover {
      background: var(--color-pink);
    }
  }

  &.variant-light-outline {
    background: #fffcf9;
    border: 1px solid rgb(26 58 50 / 0.12);
    color: black;

    &:hover {
      border: 1px solid rgb(26 58 50 / 0.24);
    }
  }

  &.variant-success-outline {
    background: #fffcf9;
    border: 1px solid var(--color-success);
    color: var(--color-success);

    &:hover {
      border: 1px solid var(--color-success);
    }
  }

  &.variant-outline-turquoise {
    background: var(--color-dark-theme);
    border: 1px solid var(--color-turquoise-90);
    color: var(--color-turquoise-90);

    &:hover {
      border: 1px solid var(--color-turquoise) !important;
      color: var(--color-turquoise) !important;
    }
  }

  &.variant-outline-pink {
    background: var(--color-dark-theme);
    border: 1px solid var(--color-pink-90);
    color: var(--color-pink-90);

    &:hover {
      border: 1px solid var(--color-pink) !important;
      color: var(--color-pink) !important;
    }
  }

  &.variant-primary[disabled] {
    background: rgb(235 234 231) !important;
    border: 1px solid rgb(235 234 231);
    color: var(--color-primary);
  }
}

.button:focus {
  box-shadow: none;
}
