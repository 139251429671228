@import 'styles/mixins';

.searchWrapper {
  width: 73%;
  margin-left: 1.5rem;

  @include breakpoint-xs {
    width: 50%;
    margin-left: 7rem;
  }

  @include breakpoint-md {
    width: 35%;
    margin-left: 5rem;
    left: 7%;
  }
}

.form {
  background-color: #f6f4f2 !important;

  &::placeholder {
    opacity: 0.4;
  }
}

.formDark {
  background-color: rgb(255 252 249 / 0.04) !important;
  color: #f6f4f2 !important;
  border-color: rgb(255 252 249 / 0.12) !important;
}

.searchIconDark {
  color: #f6f4f2 !important;
}

.input {
  background-color: #f6f4f2 !important;
  border: none;
  outline: none;
}

.input::placeholder {
  opacity: 0.4;
}

.input:focus {
  caret-color: var(--color-success);
}

.filterInput {
  border: 1px solid rgb(26 58 50 / 0.12);
}

.filterInput:focus {
  border-color: rgb(7 116 91 / 0.4);
}

.inputClearButton {
  background-color: var(--color-whiteChalk);

  &:hover {
    background-color: var(--color-muted);
  }
}