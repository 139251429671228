.sticked-model-info {
  box-shadow: 0 -4px 34px rgb(0 0 0 / 0.07);
  transform: translateY(200px);
  transition: transform 500ms;
  backdrop-filter: blur(27px);
  -webkit-backdrop-filter: blur(27px);

  &.visible {
    transform: translateY(0);
  }
}
